/*jshint esversion: 11 */
import 'bootstrap';
import {AjaxUtil, EventUtil, DomUtil} from '@hundh/contao-utils-bundle';
import Swal from 'sweetalert2';
import {Tooltip, Modal} from 'bootstrap';
import BEGTrackingEvents from './beg_tracking_events';

if (window.BEGTrackingEvents === undefined) {
    window.BEGTrackingEvents = BEGTrackingEvents;
}

class BegCommon {
    static init() {
        BegCommon.defaultGoogleConsent();
        BegCommon.initAjaxAddToCart();
        BegCommon.initAjaxRemoveFromCart();
        BegCommon.initAjaxDeleteAddress();
        BegCommon.initStickyNavbar();
        BegCommon.toggleTopLink();
        BegCommon.initMobileMenu();
        BegCommon.initMobileBottomNav();
        BegCommon.initTooltip();
        BegCommon.initMobileSubmenuLink();
        BegCommon.initLightboxElements();
        BegCommon.initSearchPage();
        BegCommon.initDropzone();
        BegCommon.initEvents();
        BegCommon.initIsoMessageModal();
        BegCommon.initIsoCheckoutFocus();
        BegCommon.initAccordion();
        BegCommon.secureExternalForm();
        BegCommon.initForm();
        BegCommon.addClassMagazineSliderItem();
        BegCommon.isoDetailViewTracking();
        BegCommon.isoDownloadTracking();
        BegCommon.addExternalLinkClass();
        BEGTrackingEvents.init();
    }

    static addExternalLinkClass() {
        const currentDomain = window.location.hostname;
        const links = document.querySelectorAll('a');

        links.forEach(link => {
            let linkHostname;

            try {
                if (link.href) {
                    linkHostname = new URL(link.href, window.location.origin).hostname;
                }
            } catch (e) {
                console.error(`Invalid URL for link: ${link.href}`);
                return;
            }

            if (linkHostname && linkHostname !== currentDomain && linkHostname !== '') {
                link.classList.add('external-link');
                link.setAttribute('aria-label', 'Externe Seite, öffnet in neuem Fenster');
                link.innerHTML += ' <span class="visually-hidden" aria-hidden="true">(öffnet in neuem Fenster)</span>';
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener');

                link.addEventListener('click', (e) => {
                    // Re-check user preference on each click
                    const userPreference = localStorage.getItem('externalLinkPreference');

                    if (userPreference === null) {
                        e.preventDefault(); // Prevent immediate navigation
                        showExternalLinkAlert(link.href, linkHostname);
                    } else {
                        // Directly open the external link in a new window without showing the modal
                        window.open(link.href, '_blank', 'noopener');
                    }
                });
            }
        });

        function showExternalLinkAlert(href, linkHostname) {
            // Remove existing modal if present
            let existingModal = document.querySelector('.external-link-modal');
            if (existingModal) {
                document.body.removeChild(existingModal);
            }

            const modalHtml = `
            <div class="external-link-modal" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); z-index: 999; display: flex; justify-content: center; align-items: center;">
                <div style="background: white; padding: 20px; border-radius: 5px; text-align: center; max-width: 32rem;">
                    <strong>Hinweis:</strong> Sie verlassen unsere Webseite. Auf der Zielseite <strong>"${linkHostname}"</strong> werden eventuell Cookies und/oder andere Objekte erzeugt, die den Datenschutz berühren. Möchten Sie fortfahren?
                    <div class="form-check" style="cursor: pointer; margin: 0.8rem 0; display: flex; justify-content: center; gap: 0.5rem;">
                      <input class="form-check-input" type="checkbox" value="" id="savePreference">
                      <label class="form-check-label" for="savePreference">Einstellung speichern</label>
                    </div>
                    <button id="cancelBtn" class="btn">Nein</button>
                    <button id="continueBtn" class="btn btn-primary">Ja</button>
                </div>
            </div>
        `;

            const modal = document.createElement('div');
            modal.innerHTML = modalHtml;
            document.body.appendChild(modal);

            // Get buttons
            const continueBtn = document.getElementById('continueBtn');
            const cancelBtn = document.getElementById('cancelBtn');
            const savePreferenceCheckbox = document.getElementById('savePreference');

            // Close the modal on Esc key press
            document.addEventListener('keydown', function escClose(event) {
                if (event.key === 'Escape') {
                    document.body.removeChild(modal);
                    document.removeEventListener('keydown', escClose); // Remove the event listener
                }
            });

            // Close the modal if clicked outside the modal content
            modal.addEventListener('click', function clickOutside(event) {
                if (event.target.classList.contains('external-link-modal')) {
                    document.body.removeChild(modal);
                    modal.removeEventListener('click', clickOutside); // Remove the event listener
                }
            });

            // Add click event for "Ja"
            continueBtn.addEventListener('click', () => {
                if (savePreferenceCheckbox.checked) {
                    localStorage.setItem('externalLinkPreference', 'yes');
                }
                window.open(href, '_blank', 'noopener');
                document.body.removeChild(modal);
            });

            // Add click event for "Nein"
            cancelBtn.addEventListener('click', () => {
                document.body.removeChild(modal);
            });
        }
    }


    static defaultGoogleConsent() {
        // https://developers.google.com/tag-platform/security/guides/consent?hl=de&consentmode=advanced#tag-manager_3
        // Define dataLayer and the gtag function.
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        // Set default consent to 'denied' as a placeholder
        // Determine actual values based on your own requirements
        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': "denied",
            'personalization_storage': "denied",
            'security_storage': 'denied',
            'wait_for_update': 500,
            'analytics_cwv': 'denied'
        });
    }

    static initMobileSubmenuLink() {
        setTimeout(() => {
            let itemsWithSubmenu = document.querySelectorAll(".mm-menu .submenu.mm-listitem > a");
            itemsWithSubmenu.forEach((item, index) => {
                if (!item.getAttribute("href").includes("#mm-")) {
                    item.setAttribute("href", item.nextElementSibling.getAttribute("href"));
                }
            });
        }, 100);
    }

    static initAjaxAddToCart() {
        const selector = '.mod_iso_productlist_slider form .add_to_cart, .mod_iso_productlist form .add_to_cart, .mod_iso_productlist_extended form .add_to_cart',
            elements = document.querySelectorAll(selector);

        if (elements.length < 1) {
            return;
        }

        EventUtil.addDynamicEventListener('click',
            selector,
            (element, event) => {
                event.preventDefault();

                let form = element.closest('form'),
                    quantity = 0,
                    formData = new FormData(form),
                    badge = document.querySelector('.mod_huh_menu .mod_iso_cart_link .badge');

                form.querySelectorAll('[name="quantity_requested"]').forEach((input) => {
                    quantity += parseInt(input.value);
                });

                if (quantity <= 0) {
                    return false;
                }

                // due to styling issues, the submit control isn't an input anymore but buttons aren't included in FormData
                formData.append(element.getAttribute('name'), element.value);

                AjaxUtil[form.getAttribute('method').toLowerCase()](
                    form.getAttribute('action'),
                    formData,
                    {
                        onSuccess: (request) => {
                            const newQuantity = parseInt(badge.innerHTML) + parseInt(quantity);

                            badge.innerHTML = newQuantity;

                            badge.setAttribute('data-quantity', newQuantity);

                            // track add to cart event
                            let itemData = JSON.parse((form.querySelector('.formbody')?.dataset?.product) ?? '{}');
                            itemData.quantity = quantity;
                            window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object
                            window.dataLayer.push({
                                event: 'Ecommerce - Add to cart',
                                event_name: 'add_to_cart',
                                ecommerce: {
                                    currency: itemData.currency ?? 'EUR',
                                    value: 0,
                                    items: [itemData]
                                }
                            });

                            Swal.fire({
                                icon: 'success',
                                timer: 0,
                                timerProgressBar: true,
                                closeButtonAriaLabel: 'Fenster schließen',
                                showCloseButton: true,

                                showConfirmButton: false,
                                html: quantity + ' Artikel wurde' + (quantity > 1 ? 'n' : '') + ' erfolgreich Ihrem Warenkorb hinzugefügt.'
                            });
                        },
                        onError: (response) => {
                            Swal.fire({
                                icon: 'error',
                                timer: 0,
                                timerProgressBar: true,
                                showCloseButton: true,
                                closeButtonAriaLabel: 'Fenster schließen',
                                showConfirmButton: false,
                                html: response.responseText
                            });
                        }
                    }
                );
            }
        );
    }

    static initAjaxRemoveFromCart() {
        let elements = document.querySelectorAll('.mod_iso_cart');

        if (elements.length < 1) {
            return;
        }

        // save the initial quantity values for the remove function
        document.querySelectorAll('.mod_iso_cart .quantity input').forEach((element) => {
            element.setAttribute('data-initial', element.value);
        });

        const selector = '.mod_iso_cart a.remove';

        elements = document.querySelectorAll(selector);

        if (elements.length < 1) {
            return;
        }

        EventUtil.addDynamicEventListener('click',
            selector,
            (element, event) => {
                event.preventDefault();

                let tableRow = element.closest('tr');
                let quantity = tableRow.querySelector('.quantity input').getAttribute('data-initial'),
                    price = DomUtil.getTextWithoutChildren(
                        tableRow.querySelector('.price.total')
                    ).replace(',', '.');

                AjaxUtil.get(
                    element.getAttribute('href'),
                    {},
                    {
                        onSuccess: () => {
                            let badge = document.querySelector('.mod_huh_menu .mod_iso_cart_link .badge');

                            badge.innerHTML = parseInt(badge.innerHTML) - parseInt(quantity);
                            badge.setAttribute('data-quantity', badge.innerHTML);

                            // track remove from cart event
                            let itemData = JSON.parse((tableRow.dataset?.product) ?? '{}');
                            itemData.quantity = quantity;
                            console.log(itemData);
                            window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object
                            window.dataLayer.push({
                                event: 'Ecommerce - Remove from cart',
                                event_name: 'remove_from_cart',
                                ecommerce: {
                                    currency: itemData.currency ?? 'EUR',
                                    value: 0,
                                    items: [itemData]
                                }
                            });

                            // adjust prices
                            element.closest('.cart-table').querySelectorAll('tfoot .price.total').forEach((priceElement) => {
                                let currentSum = DomUtil.getTextWithoutChildren(priceElement);

                                priceElement.innerHTML = (currentSum.replace(',', '.') -
                                        price).toFixed(2).replace('.', ',') +
                                    ' <span class="currency">€</span>';
                            });

                            tableRow.remove();

                            if (document.querySelectorAll('.mod_iso_cart tbody tr:not(.header)').length <= 0) {
                                location.reload();
                            }
                        },
                        onError: (response) => {
                            Swal.fire({
                                icon: 'error',
                                timer: 6000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                showConfirmButton: false,
                                closeButtonAriaLabel: 'Fenster schließen',
                                html: response.responseText
                            });
                        }
                    }
                );
            }
        );
    }

    static initAjaxDeleteAddress() {
        // $('.mod_iso_addressbook .delete').on('click', function(event) {
        //     let $deleteLink = $(this);
        //
        //     event.preventDefault();
        //
        //     if (confirm('Möchten Sie diese Adresse wirklich löschen?')) {
        //         $.get(
        //             $deleteLink.getAttribute('href'),
        //             {
        //                 success: function() {
        //                     $deleteLink.parent().
        //                     parent().
        //                     fadeOut(300, function() {
        //                         $(this).remove();
        //                         if ($('.mod_iso_addressbook .delete').length <= 0) {
        //                             location.reload();
        //                         }
        //                     });
        //                 },
        //             },
        //         );
        //     }
        // });
    }

    static initStickyNavbar() {

        const offset = document.querySelector(".header-top") && document.querySelector(".header-top").offsetHeight + 120;
        const stickyContent = ".sticky-content";

        let nodes = document.querySelector(".sticky-nav " + stickyContent) !== null &&
            document.querySelector(".sticky-nav " + stickyContent)?.childNodes.length > 0 &&
            document.querySelector(".sticky-nav " + stickyContent).childNodes ||
            document.querySelector(".header-top " + stickyContent)?.childNodes.length > 0 &&
            document.querySelector(".header-top " + stickyContent).childNodes;

        let stickyContentMain = document.querySelector(".header-top " + stickyContent)

        if (stickyContentMain) {
            stickyContentMain.style.height = stickyContentMain.clientHeight + 'px';
        }

        let stickyNav = document.querySelector(".sticky-nav");

        window.addEventListener('scroll', e => {
            if (offset) {
                if (window.pageYOffset > offset) {
                    if (nodes) {
                        let stickyNavContent = document.querySelector(".sticky-nav " + stickyContent);

                        stickyNavContent.append(...nodes);
                        nodes = stickyNavContent.childNodes;

                        stickyNav?.classList.add('show')
                    }
                    document.querySelector('.sticky-nav').classList.add('d-lg-block');
                } else {
                    if (nodes) {
                        document.querySelector(".header-top " + stickyContent).append(...nodes);
                        nodes = document.querySelector(".header-top " + stickyContent).childNodes;
                        stickyNav?.classList.remove('show')

                    }
                    document.querySelector('.sticky-nav').classList.remove('d-lg-block');
                }
            }

        });
    }

    static toggleTopLink() {
        let topLinks = document.querySelectorAll('.ce_toplink a');
        window.addEventListener('scroll', function () {
            let offset = window.pageYOffset || document.documentElement.scrollTop;

            let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

            topLinks.forEach(link => {
                if (offset >= viewportHeight) {
                    link.classList.add('visible');
                } else {
                    link.classList.remove('visible');
                }
            });
        });
    }

    //mobile icon-navigation bottom

    static initMobileBottomNav() {
        let offset = window.pageYOffset || document.documentElement.scrollTop;

        const navigation = document.querySelector('.navbar-mobile-bottom');

        let navTools = document.querySelector(".mod_huh_menu .navbar-tools");

        // Handling menu tools like watchlist button
        let mediaTools = document.querySelector('.mod_huh_menu .media-tools');
        let mobileMediaToolsWrapper = document.querySelector('.navbar .extra');
        // let extraToolWrapper = document.createElement('div');
        // let mmenuList = document.querySelector('.mm-menu .level_1.mm-listview');

        // if (mediaTools && mmenuList) {
        //     // extraToolWrapper.classList.add('extra');
        //     // mmenuList.parentNode.append(extraToolWrapper)
        // }

        const moveTools = () => {
            if (window.getComputedStyle(navigation).getPropertyValue('display') !== 'none') {
                if (mediaTools && mobileMediaToolsWrapper) {
                    mobileMediaToolsWrapper.append(...mediaTools.childNodes);
                }
                navigation.querySelector('.navbar-tools').append(...navTools.childNodes);

            } else {
                if (mediaTools && mobileMediaToolsWrapper) {
                    mediaTools.append(...mobileMediaToolsWrapper.childNodes);
                }
                navTools.append(...navigation.querySelector('.navbar-tools').childNodes);
            }
        };

        if (navigation) {

            let body = document.querySelector('body');

            body.append(navigation);

            moveTools();

            window.addEventListener('resize', () => {
                moveTools();
            });

            document.addEventListener('scroll', (e) => {
                if (document.documentElement.scrollTop > offset) {
                    navigation.classList.remove('scroll-up');
                    navigation.classList.add('scroll-down');
                    if (document.documentElement.scrollTop > 60) {
                        navigation.classList.add('hidden');
                    }
                } else {
                    navigation.classList.add('scroll-up');
                    navigation.classList.remove('hidden', 'scroll-down');
                }
                offset = document.documentElement.scrollTop <= 0 ? 0 : document.documentElement.scrollTop; // For Mobile or negative scrolling

            }, {passive: true});
        }


    }


    static initMobileMenu() {
        // setTimeOut for mmenu to load
        setTimeout(() => {

            let mmenu = document.querySelector('nav.mm-menu');

            let menuToggler = document.querySelector('.navbar-toggler');

            let mmenuFooter = document.querySelector(".mm-menu .mm-navbars_bottom .mm-navbar");

            const config = {attributes: true, childList: true, subtree: true};

            const bottomNav = document.querySelector('.navbar-mobile-bottom');

            const secondaryNav = document.querySelector('.header-top .navbar-secondary');

            let mobileSecondaryNav = document.querySelector('.mm-menu .navbar-secondary');

            const callback = (mutationsList, observer) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === "class") {
                        if (mutation.target.classList.contains('mm-menu_opened') || mutation.target.classList.contains('mm-panel_opened')) {

                            menuToggler.setAttribute('aria-expanded', true);
                            menuToggler.classList.add('is-active');
                            // Focus on first item after opened
                            mutation.target.querySelector('.mm-panel_opened [href]')?.focus()

                            if (secondaryNav) {
                                mobileSecondaryNav.append(...secondaryNav.childNodes);
                            }

                            if (bottomNav) {
                                mmenuFooter.append(bottomNav);
                            }

                        } else {

                            menuToggler.setAttribute('aria-expanded', false);
                            menuToggler.classList.remove('is-active');

                            if (secondaryNav) {
                                secondaryNav.append(...mobileSecondaryNav.childNodes);
                            }

                            if (bottomNav) {
                                document.querySelector('body').append(bottomNav);
                            }

                        }
                    }
                }
            };

            if (mmenu) {
                const menuButtonObserver = new MutationObserver(callback);
                menuButtonObserver.observe(mmenu, config);
            }

            let emptyButtons = document.querySelectorAll('button.mm-sronly');

            emptyButtons.forEach(el => {
                // el.classList.add('d-none');
                if (el.classList.contains('mm-tabstart')) {
                    el.textContent = 'Navigation Beginn';
                } else if (el.classList.contains('mm-tabend')) {
                    el.textContent = 'Navigation Ende';
                }
            });

        }, 100);

    }

    static initTooltip() {
        var tooltipTriggers = [].slice.call(document.querySelectorAll('[data-tooltip="tooltip"]'));
        var tooltipList = tooltipTriggers.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }

    static initLightboxElements() {

        const helperText = ' - zur vergrößerten Ansicht';
        const lightboxModalClass = 'glightbox-container';

        const nextText = "Vorwärtsblättern";
        const prevText = "Rückwärtsblättern";
        const closeText = "Slider schließen";

        const setAttribute = (el, attrs) => {
            for (var key in attrs) {
                el.setAttribute(key, attrs[key]);
            }
        };

        // Add helper text to existing alt attribute of img using lightbox
        let lightboxes = document.querySelectorAll('a[data-lightbox] img');

        if (lightboxes) {
            lightboxes.forEach(item => {
                item.setAttribute('alt', item.getAttribute('alt') + helperText);
            });
        }

        const config = {childList: true};

        // Add additional labels to lightbox buttons
        const callback = (mutationsList, observer) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0 && mutation.addedNodes[0].classList.contains(lightboxModalClass)) {

                    let nextBtn = mutation.target.querySelector('.gnext'),
                        prevBtn = mutation.target.querySelector('.gprev'),
                        closeBtn = mutation.target.querySelector('.gclose');

                    setAttribute(nextBtn, {'aria-label': nextText, 'title': nextText});
                    setAttribute(prevBtn, {'aria-label': prevText, 'title': prevText});
                    setAttribute(closeBtn, {'aria-label': closeText, 'title': closeText});

                }
            }
        };

        const lightboxObserver = new MutationObserver(callback);

        let body = document.querySelector('body');

        lightboxObserver.observe(body, config);
    }

    // Focus on search field after searching
    static initSearchPage() {
        if (window.location.href.indexOf('keywords=') > -1) {
            document.querySelector('#main input[name="keywords"]')?.focus()
        }
    }

    static initDropzone() {

        const config = {attributes: true, childList: true, subtree: true};
        let dzContainers = document.querySelectorAll(".dz-container");
        const prefixId = 'helper-dz-item-error-';

        dzContainers.forEach(item => {

            item.addEventListener('keydown', e => {

                if (e.key === "Enter") {

                    let parentRole = e.target.parentNode.getAttribute('data-upload-action');

                    if (parentRole === "upload") {
                        e.target.parentNode.click();
                    }
                }
            });

            const callback = (mutationsList, observer) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList' && mutation.addedNodes.length > 0 && mutation.addedNodes[0].nodeType === 1) {
                        if (mutation.addedNodes[0].classList.contains("dz-error")) {

                            let errorPreviews = mutation.target.querySelectorAll('.dz-preview.dz-error');

                            errorPreviews.forEach((item, index) => {
                                item.setAttribute('aria-describedby', prefixId + index);
                                item.querySelector('.dz-error-message').setAttribute('id', prefixId + index);
                            });

                        }
                    }
                }
            };

            const dzObserver = new MutationObserver(callback);
            dzObserver.observe(item, config);

        });

        let dropzones = document.querySelectorAll(".multifileupload.dropzone");

        dropzones.forEach(item => {

            let errorMsg = item.parentNode.nextElementSibling;

            if (errorMsg && errorMsg.classList.contains('error') && errorMsg.nodeName == "P") {
                errorMsg.setAttribute('id', item.getAttribute('aria-describedby'));
            }
        });

    }

    static initEvents() {
        // document.addEventListener('DOMNodeInserted', event => {
        //     if (event.relatedNode.classList.contains('formhybrid_submission')) {
        //         BegCommon.initDropzone();
        //     }
        // }, true);

        /** @param {MutationRecord[]} mutations */
        function observerCallback(mutations) {
            for (const mutation of mutations) {
                for (const node of mutation.addedNodes) {
                    if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('formhybrid_submission')) {
                        BegCommon.initDropzone();
                        return;
                    }
                }
            }
        }

        new MutationObserver(observerCallback).observe(document, {childList: true, subtree: true});

        // close all submenu using ESC key
        document.addEventListener('keydown', e => {
            if (e.key === 'Escape') {
                let openedElements = document.querySelector('nav.navbar').querySelectorAll('.open');
                if (openedElements.length > 0) {
                    openedElements.forEach(el => {
                        el.classList.remove('open');
                    });

                }
            }
        });
    }

    static initIsoMessageModal() {
        if (document.getElementById('isoMessageModal')) {
            let isoMsgModal = new Modal(document.getElementById('isoMessageModal'));
            isoMsgModal.show();
        }
    }

    static initIsoCheckoutFocus() {
        let isoCheckout = document.querySelector('.mod_iso_checkout');
        if (isoCheckout) {
            const keyboardfocusableElements = isoCheckout.querySelector(
                'a[href], button, input:not([type="hidden"]), textarea, select, details, [tabindex]:not([tabindex="-1"])'
            );

            if (keyboardfocusableElements) {
                keyboardfocusableElements.focus();
            }
        }
    }

    // Deeplink accordion
    static initAccordion() {

        let hash = window.location.hash;
        let accordionsHeaders = document.querySelectorAll('button[data-bs-toggle="collapse"]');

        if (accordionsHeaders.length > 0) {
            accordionsHeaders.forEach(item => {

                item.addEventListener('click', e => {
                    setTimeout(() => {
                        if (item.getAttribute('aria-expanded') === 'true') {
                            // console.log('click',e.target.getAttribute('aria-expanded'))

                            let top = item.getBoundingClientRect().top + window.pageYOffset - 80;
                            window.scrollTo({top: top, behavior: 'smooth'});
                        }
                        // item.scrollIntoView({behavior: "smooth"})
                    }, 400);

                });

                if (item.dataset.bsTarget === hash) {
                    item.click();
                }
            });
        }
    }

    // adapt from privacy center js
    static secureExternalForm() {
        setTimeout(() => {

            if (window.PrivacyCenter) {
                let privacyCenter = document.querySelector('.privacy-center');
                if (privacyCenter && privacyCenter.getAttribute('data-secure-external-links') === '1') {
                    document.querySelectorAll('form').forEach(function (element) {

                        let domain;

                        try {
                            domain = new URL(element.action);
                        } catch (_) {
                            return;
                        }

                        if (typeof domain.hostname === 'undefined' || domain.hostname === '' || domain.hostname === location.hostname) {
                            return;
                        }

                        element.querySelector('[type="submit"]').addEventListener('click', function (e) {
                            if (window.PrivacyCenter.isExternalDomainAllowed(domain.hostname)) {
                                return;
                            }

                            e.preventDefault();

                            // image -> show as alert box
                            let content = document.createElement('div'),
                                description = document.createElement('div'),
                                checkbox = document.createElement('div');

                            description.innerHTML = unescape(
                                privacyCenter.getAttribute('data-secure-external-links-text').replace('%target%', domain.hostname)
                            );

                            checkbox.innerHTML = '<label>' +
                                '<input type="checkbox" name="save-setting" value="1">&nbsp;' +
                                unescape(privacyCenter.getAttribute('data-secure-external-links-save-settings')) +
                                '</label>';

                            content.appendChild(description);
                            content.appendChild(checkbox);


                            Swal.fire({
                                html: content,
                                showCancelButton: true,
                                reverseButtons: true,
                                buttonsStyling: false,
                                confirmButtonText: privacyCenter.getAttribute('data-secure-external-links-yes'),
                                cancelButtonText: privacyCenter.getAttribute('data-secure-external-links-no'),
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                    cancelButton: "btn"
                                }
                            })
                                .then((value) => {
                                    if (value.isConfirmed) {
                                        if (document.querySelector('.swal2-modal [name="save-setting"]').checked) {
                                            window.PrivacyCenter.allowExternalDomain(domain.hostname);
                                        }

                                        element.submit();
                                    }
                                });
                        });
                    });
                }
            }
        }, 100);
    }

    //Form with reset button should submit when pressing enter, does not handle input with autocomplete yet
    static initForm() {
        let forms = document.querySelectorAll('form');

        if (forms) {
            forms.forEach(form => {
                if (form.querySelector('button[type="submit"][name*="reset"]')) {
                    let formInputs = form.querySelectorAll('input[type="text"]');

                    if (formInputs) {
                        formInputs.forEach(input => {
                            input.addEventListener('keydown', e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    form.submit();
                                }
                            });
                        });
                    }
                }

            });
        }
    }

    static addClassMagazineSliderItem() {
        const array = document.querySelectorAll('.magazine-slider-item');
        const classToAdd = 'hovered';
        if (array) {
            array.forEach(e => e.addEventListener('mouseover', () => {
                e.classList.add(classToAdd);
            }));
        }
    }

    static isoDetailViewTracking() {
        document.querySelectorAll('.product .image_container a[data-lightbox]')?.forEach((element) => {
            element.addEventListener('click', (event) => {
                // track add to cart event
                let itemData = JSON.parse((event.target.closest('.formbody, tr')?.dataset?.product) ?? '{}');
                window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object
                window.dataLayer.push({
                    event: 'Ecommerce - Item Detail View',
                    event_name: 'view_item',
                    ecommerce: {
                        currency: itemData.currency ?? 'EUR',
                        value: 0,
                        items: [itemData]
                    }
                });
            });
        });
    }

    static isoDownloadTracking() {
        document.querySelectorAll('.product .downloadlink button[type="submit"]')?.forEach((element) => {
            element.addEventListener('click', (e) => {

                if (e.target.tagName === 'BUTTON') {
                    let itemData = JSON.parse((e.target.closest('.formbody, tr')?.dataset?.product) ?? '{}');
                    let name = itemData?.item_name
                        ? itemData.item_name
                        : e.target.closest('[data-product-name]')?.dataset?.productName;

                    window.dataLayer.push({
                        event: 'gx.download',
                        event_name: 'download',
                        event_source: 'source_code',
                        download: {
                            name: name,
                            type: e.target.dataset.type,
                            url: location.origin + '/' + e.target.dataset.path
                        }
                    });
                }
                /*
                if (e.target.tagName === 'A') {

                    function getType(a) {
                        console.log(a);
                        let file = new URLSearchParams(a.href.split('?').pop()).get('file');
                        if (!file) {
                            file = a.href.split('#')[0].split('?')[0];
                        }
                        file = file?.split('/')?.pop();
                        return file?.split('.')?.pop() || '';
                    }

                    window.dataLayer.push({
                        event: 'gx.download',
                        event_name: 'download',
                        event_source: 'source_code',
                        download: {
                            name: e.target.title,
                            type: e.target.type || getType(e.target),
                            url: e.target.href
                        }
                    });
                }
                */
            });
        });
    }
}

document.addEventListener('DOMContentLoaded', BegCommon.init, true);
