const sanitizeData = (data) => window.HeimrichHannotTrackingEvents.sanitizeData(data);

export default class BEGTrackingEvents {
    static init() {
        BEGTrackingEvents.initNewsItemClose();
        BEGTrackingEvents.initMlProductList();
        BEGTrackingEvents.initVideoTracking();
    }

    static initVideoTracking() {
        const video = document.querySelector('video');
        if (video === null) {
            return;
        }
        const parent = video.closest('.ce_video') || video.closest('.ce_huh_video');
        video.trkTitle = video.title || parent?.querySelector('h1, h2, h3, h4, h5, h6, .headline')?.innerText || video.currentSrc || '';
        video.trkUrl = window.location.href.split('#')[0].split('?')[0];
        video.addEventListener('play', () => BEGTrackingEvents.onVideoPlay(video));
        video.addEventListener('pause', () => BEGTrackingEvents.onVideoPause(video));
        video.addEventListener('ended', () => BEGTrackingEvents.onVideoEnded(video));
        video.addEventListener('timeupdate', () => BEGTrackingEvents.onVideoTimeUpdate(video));
    }

    static lastVideoTime = 0;

    static onVideoTimeUpdate(video) {
        const percentage = Math.round(100 * video.currentTime / video.duration);
        if (percentage >= 100) {
            return;
        }
        const currentTime = Math.trunc(video.currentTime);
        if (currentTime % 5 !== 0 || currentTime === BEGTrackingEvents.lastVideoTime) {
            return;
        }
        BEGTrackingEvents.lastVideoTime = currentTime;
        window.dataLayer.push({
            event: 'gx.video_progress',
            event_name: 'video_progress',
            event_source: 'source_code',
            video_progress: {
                duration: video.duration,
                percentage: percentage,
                name: video.trkTitle,
                provider: 'self',
                url: video.trkUrl
            }
        });
    }

    static onVideoPlay(video) {
        window.dataLayer.push({
            event: 'gx.video_play',
            event_name: 'video_play',
            event_source: 'source_code',
            video_play: {
                duration: video.duration,
                percentage: Math.round(100 * video.currentTime / video.duration),
                name: video.trkTitle,
                provider: 'self',
                url: video.trkUrl
            }
        });
    }

    static onVideoPause(video) {
        if (video.duration === video.currentTime) {
            return;
        }
        window.dataLayer.push({
            event: 'gx.video_pause',
            event_name: 'video_pause',
            event_source: 'source_code',
            video_pause: {
                duration: video.duration,
                percentage: Math.round(100 * video.currentTime / video.duration),
                name: video.trkTitle,
                provider: 'self',
                url: video.trkUrl
            }
        });
    }

    static onVideoEnded(video) {
        window.dataLayer.push({
            event: 'gx.video_completed',
            event_name: 'video_completed',
            event_source: 'source_code',
            video_completed: {
                duration: video.duration,
                name: video.trkTitle,
                provider: 'self',
                url: video.trkUrl
            }
        });
    }

    static initMlProductList() {
        const list = document.querySelector('[data-ml-products="list"]');
        if (list === null) {
            return;
        }
        const links = list.querySelectorAll('a.details');
        links.forEach((link) => {
            const li = link.closest('li[data-ml-product]');
            if (li === null || !li.dataset.mlProduct) {
                return;
            }
            let data;
            try {
                data = JSON.parse(li.dataset.mlProduct);
            } catch (e) {
                console.error(e);
                return;
            }
            link.addEventListener('click', () => BEGTrackingEvents.onPictureSelect(data));
        });
    }

    static onPictureSelect(data) {
        window.dataLayer.push({
            event: 'gx.picture_select',
            event_name: 'picture_select',
            event_source: 'source_code',
            picture_select: {
                id: data.id ?? '',
                name: data.name ?? ''
            }
        });
    }

    static initNewsItemClose() {
        const firstNewsItem = document.querySelector('[data-news-item]');
        if (firstNewsItem === null) {
            return;
        }
        let data;
        try {
            data = JSON.parse(firstNewsItem.dataset.newsItem);
            window.addEventListener('beforeunload', () => BEGTrackingEvents.onBeforeUnload(data));
        } catch (e) {
            console.error(e);
        }
    }

    static onBeforeUnload(data) {
        window.dataLayer.push({
            event: 'gx.article_close',
            event_name: 'article_close',
            event_source: 'source_code',
            article_close: {
                name: data.headline ?? '',
            }
        })
    }

    static accordionExpand(event, eventConfig) {
        return sanitizeData({
            "title": event.target.innerText || (event.target.dataset.ariaControls ?? ''),
        });
    }

    static jobListingOpen(event, eventConfig) {
        return sanitizeData({
            "headline": event.target.querySelector('.headline')?.innerText.trim() || '',
            "id": event.target.closest("[data-contao-id]")?.dataset.contaoId || ''
        });
    }

    static downloadLinkClick(event, eventConfig) {
        const link = event.target.tagName === 'A' ? event.target : event.target.closest('a');

        if (link?.fired || !link) return;
        link.fired = true;

        let filename = link.href.split('#')[0].split('/').pop().split('?')[0];

        function getType(a) {
            let file = new URLSearchParams(a.href.split('?').pop()).get('file');
            if (!file) {
                file = a.href.split('#')[0].split('?')[0];
            }
            file = file?.split('/')?.pop();
            return file?.split('.')?.pop() || '';
        }

        let link_alt = filename.includes('.') ? filename : link.innerText.trim();

        let type = filename.split('.').pop();
        return sanitizeData({
            "url": link.href || '',
            "title": link.title || link_alt || '',
            "type": getType(link) || ''
        });
    }
}